import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ( props ) => {

	return (
		<Helmet bodyAttributes={{ class: props.bodyClasses }} >
			<html lang="en" className={ props.documentClasses ? props.documentClasses : null } />

			{ props.siteTitle && props.title && (
				<title>{ props.siteTitle } &mdash; { props.title }</title>
				)
			}

			<meta
		        name="viewport"
		        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
		    />

		    { props.siteDescription && (
			    <meta name="description" content={ props.siteDescription } />
		    )}

		</Helmet>
	);
}

export default SEO;